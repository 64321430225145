<template>
<div v-if="pack && pack.slug !== 'ydkj-classic'" class="plat-icons-container">
    <div v-for="(platform, idx) in platformOptions" :key="idx">
        <a
            :href="storefrontLink(pack.slug, platform.vendor)"
            target="_blank"
            :aria-label="`link to ${platform.vendor} storefront`"
            tabindex="0"
            class="external-storefront"
            :class="[platform.vendor, getProductClass(pack)]"
        >
            <img
                :src="`/images/icons/platforms/${platform.icon}.svg`"
                :alt="platform.text"
                class="storefront-icon"
            />
        </a>
    </div>
</div>
</template>

<script lang="ts">
import type { PropType } from 'vue'

import { getStorefrontLink } from '$services/storefronts'
import type { JBGWeb } from '$types/JBGWeb'

export default defineNuxtComponent({
    props: {
        pack: Object as PropType<JBGWeb.PackDetail>,
        platformOptions: Object,
        fellBackToEn: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        isBundle() {
            switch (this.$route.params.bundleslug) {
            case 'the-jackbox-party-trilogy':
            case 'the-jackbox-party-trilogy-2':
            case 'the-jackbox-party-trilogy-3':
            case 'the-jackbox-party-quadpack':
            case 'the-jackbox-party-quintpack':
            case 'the-jackbox-party-quintpack-2':
            case 'the-jackbox-party-bundle':
            case 'the-jackbox-decade-bundle':
                return true
            default:
                return false
            }
        }
    },

    methods: {
        // getPlatformIcon(platformIcon: string) {
        //     return new URL(`../../assets/icons/${platformIcon}.svg`, import.meta.url).href
        // },
        getProductClass(pack: JBGWeb.PackDetail) {
            if (!pack) return
            let slicedSlug = pack.slug.slice(18)

            // party starter
            if (pack.slug === 'the-jackbox-party-starter') {
                slicedSlug = pack.slug.slice(12)
            }

            // standalones
            if (!pack.gameDetails) {
                slicedSlug = pack.slug
            }

            return slicedSlug
        },

        storefrontLink(slug: string, platform: string): URL | undefined {
            if (!platform || !slug) return
            if (!this.pack) return
            return getStorefrontLink(this.pack.slug, slug, platform, this.fellBackToEn, this.isBundle)
        }
    }
})
</script>

<style scoped lang="scss">
@use "$styles/kit.scss" as *;

.plat-icons-container {
    display: flex;
    justify-content: space-around;
    padding: 12px 0;

    .storefront-icon {
        margin: 0 6px;
        height: 28px;
        width: 28px;

        @include mq-small {
            margin: 0px 2px;
            height: 20px;
            width: 20px;
        }

        @include mq-xsmall {
            height: 20px;
            width: 20px;
        }
    }
}
</style>
